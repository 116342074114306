<template>
  <div class="flex py-4 justify-center flex-1">
    <div class="flex flex-col rounded-lg gap-6">
      <form-layout class="col-span-2 h-full">
        <template v-slot:title>
          {{ $t("testfleet.breakdown.title") }}
        </template>
        <template v-slot:description>
          {{ $t("testfleet.breakdown.description") }}
        </template>

        <template v-slot:form>

          <div class="flex flex-col gap-8">
            <!-- Panels -->
            <Panel :header="$t('testfleet.breakdown.breakdown.title')" class="mx-4">

              <template #content>
                <p
                  class="m-0 px-4 list-disc marker:text-tertiary space-y-1 break-words"
                  v-html="$t('testfleet.breakdown.breakdown.description')"></p>

              </template>

            </Panel>


            <Panel :header="$t('testfleet.breakdown.damage.title')" class="mx-4">
              <template #content>


                <p
                  class="m-0 px-4 list-disc marker:text-tertiary space-y-1 break-words"
                  v-html="$t('testfleet.breakdown.damage.description')"></p>


              </template>
            </Panel>
            <Panel :header="$t('testfleet.breakdown.theft.title')" toggleable class="mx-4">
              <template #content>


                <p
                  class="m-0 px-4 list-disc marker:text-tertiary space-y-1 break-words"
                  v-html="$t('testfleet.breakdown.theft.description')"></p>

              </template>
            </Panel>

            <Panel :header="$t('testfleet.breakdown.lostKey.title')" toggleable class="mx-4">
              <template #content>


                <p
                  class="m-0 px-4 list-disc marker:text-tertiary space-y-1 break-words"
                  v-html="$t('testfleet.breakdown.lostKey.description')"></p>


              </template>
            </Panel>
            <Button data-cy="back-btn" variant="default" @click="() => router.back()"
              class="ps-4 pe-6 py-3 gap-2 text-nowrap text-sm flex items-center justify-center mx-4"
              icon-left="ArrowLeft" :label="$t('testfleet.booking.form.button.back')" />
          </div>

        </template>

      </form-layout>
    </div>
  </div>
</template>

<script setup lang="ts">
import FormLayout from '../components/Form/FormLayout.vue'
import Button from '@/components/Button/Button.vue'
import Panel from '../components/Panel/Panel.vue'
//@ts-ignore
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue'
//@ts-ignore
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
//@ts-ignore
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import { useRouter } from 'vue-router';
const router = useRouter();
</script>
