
import './assets/styles/base.css'
import 'v-calendar/style.css';
import 'floating-vue/dist/style.css';

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

import VCalendar from 'v-calendar';
import * as Sentry from "@sentry/vue";

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
// @ts-ignore
import o2oPreset from './presets/o2o'
import { VueTolgee } from '@tolgee/vue';
import { tolgee } from './i18n/tolgee';
import { useAuthStore } from './stores/Auth/AuthStore';

import FloatingVue from 'floating-vue';

FloatingVue.options.themes.tooltip.triggers = ['hover', 'focus', 'touch', 'click'];
// Create Pinia Store
const pinia = createPinia()

import Loading from './components/Home/Loading.vue';

const loading = createApp(Loading);

loading.use(PrimeVue, {
  unstyled: true,
  pt: o2oPreset,
});

loading.use(VueTolgee, { tolgee: tolgee });
loading.use(pinia);
loading.mount('#loading');

// Create Vue App
const app = createApp(App)

app.use(VueTolgee, { tolgee: tolgee });

// Use Pinia Store
app.use(pinia);

// Use PrimeVue
app.use(PrimeVue, {
  unstyled: true,
  pt: o2oPreset,
});

app.use(FloatingVue);
// Use PrimeVue Toast
app.use(ToastService);

// Use VCalendar
app.use(VCalendar, {
  locales: {
    'nl': {
      firstDayOfWeek: 2,
      masks: {
        title: 'MMMM YYYY',
        input: 'DD/MM/YYYY',
        weekdays: 'WW',
      }
    },
    'en': {
      firstDayOfWeek: 2,
      masks: {
        title: 'MMMM YYYY',
        input: 'DD/MM/YYYY',
        weekdays: 'WW',
      }
    },
    'fr': {
      firstDayOfWeek: 2,
      masks: {
        title: 'MMMM YYYY',
        input: 'DD/MM/YYYY',
        weekdays: 'WW',
      }
    }
  }
});

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://4554cc76a7feaf0cf70d00c8f2b15a3c@o1173669.ingest.us.sentry.io/4506942665654272",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["booking.o2o.be"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
const authStore = useAuthStore();

app.provide('authStore', authStore);
app.provide('tolgee', tolgee);

// Authenticate the user before mounting the main app
authStore.authenticate().then(() => {
  // Use vue router now so it doesn't interfere with the loading screen
  app.use(router);
  app.mount('#app');
  loading.unmount();
});

