<template>
    <Card :pt="BookedBikeCardPreset" class="bg-white" v-if="bike">
        <template #header>
            <BikeCode :bicycleCode="bike.bicycle_code" class="absolute bottom-[0.5rem] left-[0.5rem] z-10" />
            <Image :alt="bike.brand + ' ' + bike.model" preview>
                <template #image>
                    <img class="p-4 max-h-[250px] lg:max-w-[300px]" :src="bike.image_url" :alt="bike.brand + ' ' + bike.model" />
                </template>
                <template #preview="slotProps">
                    <img :src="bike.image_url" alt="preview" :style="slotProps.style" @click="slotProps.onClick" />
                </template>
            </Image>
        </template>
        <template #title>

        </template>
        <template #content>
            <div class="flex items-center justify-between px-5">
                <div class="flex flex-col lg:flex-row lg:gap-8 lg:items-center w-full">
                    <div class="space-y-5 font-roboto text-sm lg:w-[75%]">
                        <h2>{{ bike.brand }} {{ bike.model }}</h2>
                        <div>
                            <p class="font-regular" v-if="bike.propulsion">

                                {{ $t('testfleet.bike.attributes.propulsion.label') }}:

                                <span class="font-medium">

                                    {{
        $t('testfleet.bike.attributes.propulsion.' + bike.propulsion) }}
                                </span>
                            </p>
                            <p class="font-regular" v-if="bike.frame_type">
                                {{ $t('testfleet.bike.attributes.modelType.label') }}:

                                <span class="font-medium">

                                    {{
        $t('testfleet.bike.attributes.modelType.' + bike.frame_type) }}
                                </span>
                            </p>
                            <p class="font-regular" v-if="bike.range">
                                {{ $t('testfleet.bike.attributes.range') }}:

                                <span class="font-medium">

                                    {{ bike.range + ' km' }}
                                </span>
                            </p>
                            <p class="font-regular gap-1" v-if="lease_price">
                                {{ $t("testfleet.bike.attributes.lease_price.label") }}:
                                <span v-if="bike.lease_price" v-html="lease_price" class="font-medium" />*
                            </p>

                        </div>
                        <!-- Collapsable Button to show fully  -->
                        <div class="flex flex-col items-start space-y-2.5" v-if="description">
                            <p class="font-regular pt-2"
                                v-html="showMore ? description : description?.slice(0, MAX_DESCRIPTION_LENGTH)"></p>
                            <button class="flex justify-center items-center font-regular gap-1"
                                @click="showMore = !showMore" v-if="description.length > MAX_DESCRIPTION_LENGTH">
                                <span>
                                    {{ showMore ? $t('testfleet.booked.showLess') : $t('testfleet.booked.showMore') }}
                                </span>
                                <chevron-down :size="18" :class="{ 'transform rotate-180': showMore }" />
                            </button>
                        </div>

                    </div>
                    <div class="py-[30px] w-full">
                        <ReservationDetail :location="booking.deployment.location" :booking="booking"
                            :booking-status="booking.status">
                        </ReservationDetail>
                    </div>
                </div>
            </div>
            <div class="bg-primary-50 flex flex-col gap-2.5 justify-center py-8 px-5 lg:flex-row">
                <BookedBikeButton :disabled="pickupDisabled" v-if="booking.status === BookingStatus.Confirmed"
                    @click="() => { router.push({ path: 'my-bookings/' + booking.id + '/pickup' }) }">
                    <template #icon>
                        <span
                            class="bg-primary text-white flex justify-center items-center rounded-full min-w-[44px] h-[44px]">
                            <Bike :size="24" />
                        </span>
                    </template>

                    <template #default>

                        {{ $t('testfleet.booked.buttons.pickUp') }}
                        <span v-if="pickupDisabled" class="font-normal text-xs">
                            {{
        $t('testfleet.booked.buttons.pickUp.availableFrom') }}
                            {{ 
                            
                                formatDate(new Date(booking.start_date), $tolgee.getLanguage() || 'nl-BE', 'digit') 
                            }}
                        </span>
                    </template>

                </BookedBikeButton>

                <BookedBikeButton :disabled="dropOffDisabled" v-if="booking.status === BookingStatus.PickedUp"
                    @click="() => { router.push({ path: 'my-bookings/' + booking.id + '/dropoff' }) }">
                    <template #icon>
                        <span
                            class="bg-primary text-white flex justify-center items-center rounded-full min-w-[44px] h-[44px]">
                            <Bike :size="24" />
                        </span>
                    </template>
                    <template #default>
                        {{ $t('testfleet.booked.buttons.return') }}
                        <span v-if="dropOffDisabled" class="font-normal text-xs">
                            {{
        $t('testfleet.booked.buttons.pickUp.availableFrom') }}
                            {{ new Date(booking.end_date).toLocaleDateString() }}
                        </span>
                    </template>
                </BookedBikeButton>

                <BookedBikeButton @click="downloadStarterGuide" :disabled="starterGuideDisabled">
                    <template #icon>
                        <span
                            class="bg-secondary text-white flex justify-center items-center rounded-full min-w-[44px] h-[44px]">
                            <tray-arrow-down :size="24" />
                        </span>
                    </template>
                    <template #default>
                        {{ $t('testfleet.booked.buttons.downloadStartersGuide') }}
                        <span v-if="starterGuideDisabled" class="font-normal text-xs">
                            {{ $t('testfleet.booked.buttons.notAvailable') }}
                        </span>
                    </template>
                </BookedBikeButton>


                <BookedBikeButton v-if="booking.status === BookingStatus.Confirmed"
                    @click="() => { router.push({ path: '/my-bookings/' + booking.id + '/cancel' }) }">
                    <template #icon>
                        <span
                            class="bg-neutral-600 text-white flex justify-center items-center rounded-full min-w-[44px] h-[44px]">
                            <close :size="24" />
                        </span>
                    </template>
                    <template #default>
                        {{ $t('testfleet.booked.buttons.cancel') }}
                    </template>
                </BookedBikeButton>
                <BookedBikeButton v-if="booking.status === BookingStatus.PickedUp"
                    @click="() => { router.push({ path: '/breakdown-assistance' }) }">
                    <template #icon>
                        <span
                            class="bg-tertiary text-white flex justify-center items-center rounded-full min-w-[44px] h-[44px]">
                            <ShieldStarOutline :size="24" />
                        </span>
                    </template>
                    <template #default>
                        {{ $t('testfleet.booked.buttons.damage') }}
                    </template>
                </BookedBikeButton>

            </div>
        </template>
    </Card>
</template>

<script lang="ts" setup>
import BookedBikeCardPreset from "../../presets/o2o/BookedBikeCard";
import Card from "primevue/card";
import { computed } from 'vue';

import { getCurrentLocaleValue } from '../../utils/i18n';
import { ref } from 'vue';
//@ts-ignore
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
//@ts-ignore
import Bike from 'vue-material-design-icons/Bike.vue';
//@ts-ignore
import Close from 'vue-material-design-icons/Close.vue';
//@ts-ignore
import TrayArrowDown from 'vue-material-design-icons/TrayArrowDown.vue';
//@ts-ignore
import ShieldStarOutline from 'vue-material-design-icons/ShieldStarOutline.vue';
import ReservationDetail from '@/components/Booking/ReservationDetail/ReservationDetail.vue';
import BookedBikeButton from '@/components/BookedBikeCard/BookedBikeButton.vue';
import { Booking, BookingStatus } from '@/services/booking/types';
import { useTolgee } from '@tolgee/vue';
import { useRouter } from "vue-router";
import BikeCode from "../BikeCode/BikeCode.vue";
import Image from "primevue/image";
import { formatDate } from "@/utils/date";

const MAX_DESCRIPTION_LENGTH = 50; // Max length of the description before it gets truncated
const { booking } = defineProps({
    booking: {
        type: Object as () => Booking,
        required: true,
    }
})

const bike = booking.bicycle;

const showMore = ref(false);
const router = useRouter();


const description = computed(() => {
    return getCurrentLocaleValue(booking.bicycle?.description!, useTolgee().value.getLanguage()!);
})

const pickupDisabled = computed(() => {
    // Is only enabled when date is between start and end date and status is confirmed
    const startDate = new Date(booking.start_date);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(booking.end_date);
    endDate.setHours(23, 59, 59, 999);
    const currentDate = new Date();
    return currentDate < startDate || currentDate > endDate || booking.status !== BookingStatus.Confirmed;
})


const dropOffDisabled = computed(() => {
    // Is only enabled when date after the start date and status is picked up
    const startDate = new Date(booking.start_date);
    startDate.setHours(0, 0, 0, 0);

    const currentDate = new Date();
    return currentDate < startDate || booking.status !== BookingStatus.PickedUp;
})

const starterGuideDisabled = computed(() => {
    return !bike?.starter_guide_url;
})

const downloadStarterGuide = () => {
    bike?.starter_guide_url && window.open(bike.starter_guide_url, '_blank');
}
const lease_price = computed(() => {
    return getCurrentLocaleValue(
        bike?.lease_price!,
        useTolgee().value.getLanguage()!
    );
});
</script>
