import AxiosClient from "../api";
import {
  AuthResponseDto,
  RequestVerificationDto,
  VerifyRequestDto,
} from "./types";

const requestVerificationCode = async (
  otpRequestDto: RequestVerificationDto
): Promise<boolean> => {
  try {
    await AxiosClient.post("/auth/send-verification-email", otpRequestDto);
    return true;
  } catch (error) {
    return false;
  }
};

const authenticateWithOtp = async (
  otpCode: string
): Promise<AuthResponseDto | null> => {
    const response = await AxiosClient.get("/auth/otp/" + otpCode);
    const data = response.data as AuthResponseDto;
    // Return the token
    return data;
};

const authenticateWithEmail = async (email: string): Promise<any> => {
    const response = await AxiosClient.get("/auth/login-with-email/" + email);
    return response.data;
};

const authenticateAdmin = async (email: string): Promise<any> => {
  const response = await AxiosClient.get(
    "/auth/deployment-admins/email/" + email
  );
  return response.data;
};

const authenticateWithVerificationCode = async (
  verificationDto: VerifyRequestDto
): Promise<AuthResponseDto | null> => {
  const response = await AxiosClient.post(
    "/auth/verify-email",
    verificationDto
  );
  const data = response.data as AuthResponseDto;
  return data;
};

export const AuthService = {
  authenticateWithEmail,
  authenticateWithOtp,
  authenticateWithVerificationCode,
  requestVerificationCode,
  authenticateAdmin,
};
