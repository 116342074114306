<template>
    <div class="lg:p-8 w-full bg-linear">
        <nav
            class="w-full py-4 px-[14px]  relative overflow-hidden shadow flex items-center justify-between bg-white lg:rounded-xl">
            <router-link to="/" class="inline-flex items-center gap-2">

                <!-- Logo-->
                <Logo class="w-[54px] h-[54px]" />
                
                <!--  -->
                <span class="font-semibold text-xl hidden md:block">
                    {{ $t('testfleet.navbar.title', "Fietskaravaan") }}
                </span>
            </router-link>


    
            <div class="flex gap-4" v-if="authStore.isAuthenticated">
                <DeploymentLinkDialog class="z-[1]" v-if="isDeploymentAdmin" />
                <Button data-cy="navbar-button"
                    class="bg-white border px-3 py-3 font-semibold text-sm flex items-center z-[1] justify-between rounded-full"
                    @click="toggle">

                    <Menu :size="20" :class="{ 'transform rotate-180': panel && panel.visible }" />

                </Button>
                <OverlayPanel ref="panel" class="z-[10]">

                    <div class="w-full font-semibold text-xs flex flex-col min-w-[200px]" v-if="authStore.isAuthenticated && !userStore.user">
                        <div class="w-full">
                            <p class="px-4 pt-4 pb-2 text-tertiary" v-if="isDeploymentAdmin">
                                {{
                        $t('testfleet.navbar.employee') }}
                            </p>
                            <router-link data-cy="nav-start-item" @click="toggle" to="/" class="py-3 pb-3 px-4 block">
                                {{ $t('testfleet.navbar.start') }}
                            </router-link>
                            <hr class="border-neutral-50" />
                            <router-link data-cy="nav-book-item" @click="toggle" to="/book"
                                class="py-3 pb-3 px-4 block">
                                <div class="inline-flex h-5.5 justify-center items-center gap-1 flex-shrink-0">
                                    {{ $t('testfleet.navbar.book.guest') }}
                                </div>
                            </router-link>
                            <hr class="border-neutral-50" />
                            <router-link data-cy="nav-book-item" @click="toggle" to="/login"
                                class="py-3 pb-3 px-4 block">
                                <div class="inline-flex h-5.5 justify-center items-center gap-1 flex-shrink-0">
                                    {{ $t('testfleet.navbar.login') }}
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div class="w-full font-semibold text-xs flex flex-col min-w-[200px]" v-if="shouldShowUser">
                        <div class="w-full">
                            <p class="px-4 pt-4 pb-2 text-tertiary" v-if="isDeploymentAdmin">
                                {{
                        $t('testfleet.navbar.employee') }}
                            </p>
                            <router-link data-cy="nav-start-item" @click="toggle" to="/" class="py-3 pb-3 px-4 block">
                                {{ $t('testfleet.navbar.start') }}
                            </router-link>
                            <hr class="border-neutral-50" />
                            <router-link data-cy="nav-my-bookings-item" @click="toggle" to="/my-bookings"
                                class="py-3 pb-3 px-4 block">
                                <div class="inline-flex justify-center items-center gap-2 flex-shrink-0">
                                    {{ $t('testfleet.navbar.reservations') }}
                                    <span
                                        class="w-6 h-6 flex items-center justify-center rounded-full text-white bg-tertiary">{{
                        bookingCount }}</span>
                                </div>

                            </router-link>
                            <hr class="border-neutral-50" />
                            <router-link data-cy="nav-book-item" @click="toggle" to="/book"
                                class="py-3 pb-3 px-4 block">
                                <div class="inline-flex h-5.5 justify-center items-center gap-1 flex-shrink-0">
                                    {{ $t('testfleet.navbar.book') }}
                                </div>
                            </router-link>
                        </div>

                        <!-- HR Section -->
                        <div class="w-full px-2 py-2" v-if="isDeploymentAdmin">
                            <div class="bg-neutral-50 rounded-md py-4">

                                <p class="px-4 pb-2 text-tertiary">
                                    {{ $t('testfleet.navbar.hr') }}
                                </p>
                                <!-- Calendar -->
                                <router-link data-cy="nav-hr-calendar" @click="toggle" to="/hr" class="px-4 py-3 pb-3 block">
                                    <div class="inline-flex h-5.5 justify-center items-center gap-1 flex-shrink-0">
                                        {{ $t('testfleet.navbar.hr-calendar') }}
                                    </div>
                                </router-link>

                                <hr class="border-neutral-100" />
                                <!-- Settings -->
                                <router-link data-cy="nav-hr-settings" @click="toggle" to="/hr/settings"
                                    class="px-4 py-3 block">
                                    <div class="inline-flex h-5.5 justify-center items-center gap-1 flex-shrink-0">
                                        {{ $t('testfleet.navbar.hr-settings') }}
                                    </div>
                                </router-link>

                            </div>

                        </div>

                        <!-- General Section -->
                        <div class="bg-primary-50 flex flex-col p-4 gap-2 rounded-b-xl">
                            <div class="flex gap-1 flex-col">
                                <span>
                                    {{ $t('testfleet.navbar.logged_in') }}
                                </span>
                                <span class="text-neutral-500 font-light text-xs">
                                    {{ userStore.getUser?.email }}
                                </span>
                            </div>
                            <span data-cy="nav-logout-item" class="block cursor-pointer" @click="logout">
                                {{ $t('testfleet.navbar.logout') }}
                            </span>
                        </div>
                    </div>
                </OverlayPanel>
            </div>


            <img src="../../assets/blobs/heart.svg" class="absolute right-16 top-[-80px]" />
            <img src="../../assets/blobs/circle.svg" class="absolute bottom-[-130px] right-[-90px]" />
        </nav>
    </div>

</template>

<script setup lang="ts">
import { ref } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
// @ts-ignore
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
// @ts-ignore
import Menu from 'vue-material-design-icons/Menu.vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../../stores/Auth/AuthStore';
import { useUserStore } from '../../stores/User/UserStore';
import { computed } from 'vue';
import { BookingStatus } from '@/services/booking/types';
import DeploymentLinkDialog from '@/components/DeploymentLinkDialog/DeploymentLinkDialog.vue';
import Logo from '@/assets/logo/logo.svg'

const authStore = useAuthStore();
const userStore = useUserStore();
const router = useRouter();



const shouldShowUser = computed(() => authStore.isAuthenticated && userStore.getUser);

const panel = ref();
const toggle = (event: any) => {
    panel.value?.toggle(event);
}

const logout = () => {
    authStore.logout();
    panel.value?.toggle();
    router.push('/');
};

const isDeploymentAdmin = computed(() => userStore.isDeploymentAdmin);

const bookingCount = computed(() => {
    return userStore.getUser?.bookings?.filter(booking => [BookingStatus.Confirmed, BookingStatus.PickedUp].includes(booking.status)).length;
});

</script>

<style>
.panel-shadow {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

.bg-linear {
    background: linear-gradient(180deg, #F3F5FF 0%, #F3F5FF 38.5%, #FFFFFF 100%);
}
</style>
