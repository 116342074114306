// router/index.js
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import MainLayout from "../layouts/MainLayout.vue";
import Deployment from "../views/Deployment.vue";
import Bike from "../views/Bike.vue";
import Booked from "../views/Booked.vue";
import Login from "../views/Login.vue";

import PickupConfirm from "../views/Booked/PickupConfirm.vue";
import Home from "@/views/Home.vue";
import DropoffConfirm from "@/views/Booked/DropoffConfirm.vue";
import Cancel from "@/views/Booked/Cancel.vue";
import BreakDownAssistance from "@/views/BreakDownAssistance.vue";
import { AuthGuard, AuthType } from "./AuthGuard";
import Calendar from "@/views/Admin/Calendar.vue";
import Settings from "@/views/Admin/Settings.vue";
import { CancelGuard, DropoffGuard, PickupGuard } from "./guards/BookingGuards";
import { useBikeStore } from "@/stores/Bike/BikeStore";
import ExpiredLink from "@/views/ExpiredLink.vue";


export const routes: RouteRecordRaw[] = [
  {
    path: "/book/:id",
    name: "Bike",
    meta: {
      layout: MainLayout,
      authType: AuthType.DEPLOYMENT_CODE,
    },
    // @ts-ignore
    beforeEnter: async (to, from, next) => {
      let bikeId = to.params.id;
      const bike = useBikeStore().getBike(Number(bikeId));
      if (bike?.inactive) {
        return next({ path: "/book" });
      }
      next();
    },
    component: Bike,
  },
  {
    path: "/book",
    name: "Book",
    meta: {
      layout: MainLayout,
      authType: AuthType.DEPLOYMENT_CODE,
    },
    component: Deployment,
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      layout: MainLayout,
      authType: AuthType.PUBLIC,
    },
    component: Login,
  },
  {
    path: "/expired-link",
    name: "Expired Link",
    meta: {
      layout: MainLayout,
      authType: AuthType.PUBLIC,
    },
    component: ExpiredLink,
  },
  {
    path: "/my-bookings/:id/pickup",
    name: "Pickup Confirm",
    meta: {
      layout: MainLayout,
      authType: AuthType.JWT_TOKEN,
    },
    beforeEnter: PickupGuard,
    component: PickupConfirm,
  },
  {
    path: "/my-bookings/:id/cancel/",
    name: "Cancel Booking",
    meta: {
      layout: MainLayout,
      authType: AuthType.JWT_TOKEN,
    },
    beforeEnter: CancelGuard,
    component: Cancel,
  },
  {
    path: "/my-bookings/:id/dropoff",
    name: "Dropoff Confirm",
    meta: {
      layout: MainLayout,
      authType: AuthType.JWT_TOKEN,
    },
    beforeEnter: DropoffGuard,
    component: DropoffConfirm,
  },
  {
    path: "/breakdown-assistance",
    name: "Breakdown Assistance",
    meta: {
      layout: MainLayout,
      authType: AuthType.PUBLIC,
    },
    component: BreakDownAssistance,
  },
  {
    path: "/my-bookings",
    name: "my_bookings",
    meta: {
      layout: MainLayout,
      authType: AuthType.JWT_TOKEN,
    },
    component: Booked,
  },
  {
    path: "/",
    name: "Homepage",
    meta: {
      layout: MainLayout,
      authType: AuthType.DEPLOYMENT_CODE,
    },
    component: Home,
  },
  {
    path: "/hr",
    name: "HR",
    meta: {
      layout: MainLayout,
      authType: AuthType.DEPLOYMENT_ADMIN,
    },
    component: Calendar,
  },
  {
    path: "/hr/settings",
    name: "HR Settings",
    meta: {
      layout: MainLayout,
      authType: AuthType.DEPLOYMENT_ADMIN,
    },
    component: Settings,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({ history: createWebHistory(), routes });

router.beforeEach(AuthGuard);
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
