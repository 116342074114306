<template>
  <Card :pt="BikeCardPreset">
    <template #header>
      <BikeCode :bicycleCode="bike.bicycle_code" class="absolute bottom-[0.5rem] left-[0.5rem] z-10" />
      <Image :alt="bike.brand + ' ' + bike.model" preview>
        <template #image>
          <img class="p-4 max-h-[250px]" :src="bike.image_url" :alt="bike.brand + ' ' + bike.model" />
        </template>
        <template #preview="slotProps">
          <img :src="bike.image_url" alt="preview" :style="slotProps.style" @click="slotProps.onClick" class="max-w-[200px] max-w[200px] md:max-w-[400px] md:max-h-[400px] lg:max-w-[600px] lg:max-h-[600px]" />
        </template>
      </Image>
    </template>
    <template #title>
      <h1>{{ bike.brand }} {{ bike.model }}</h1>
    </template>
    <template #content>
      <div class="space-y-8 text-base font-roboto pb-5">
        <div>
          <p class="font-regular" v-if="props.bike.propulsion">
            {{ $t("testfleet.bike.attributes.propulsion.label") }}:
            <span class="font-medium">
              {{
    $t(
      "testfleet.bike.attributes.propulsion." + props.bike.propulsion
    )
  }}
            </span>
          </p>
          <p class="font-regular" v-if="props.bike.frame_type">
            {{ $t("testfleet.bike.attributes.frameType.label") }}:
            <span class="font-medium">
              {{
    $t("testfleet.bike.attributes.frameType." + props.bike.frame_type)
  }}
            </span>
          </p>
          <p class="font-regular" v-if="props.bike.range">
            {{ $t("testfleet.bike.attributes.range") }}:
            <span class="font-medium">
              {{ props.bike.range + " km" }}
            </span>
          </p>
          <p class="font-regular gap-1" v-if="lease_price">
            {{ $t("testfleet.bike.attributes.lease_price.label") }}:
            <span v-if="bike.lease_price" v-html="lease_price" class="font-medium" />*
          </p>
        </div>
        <hr class="border-primary-100" />
        <p v-if="description" v-html="description" />
      </div>
    </template>
  </Card>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Bike } from "@/services/bikes/types";
import { useTolgee } from "@tolgee/vue";
import { getCurrentLocaleValue } from "@/utils/i18n";
import BikeCardPreset from "@/presets/o2o/BikeCard/info";
import Card from "primevue/card";
import BikeCode from "../BikeCode/BikeCode.vue";
import Image from "primevue/image";
const props = defineProps({
  bike: {
    type: Object as () => Bike,
    required: true,
  },
});
// TODO: Find a better way to handle this
const description = computed(() => {
  return getCurrentLocaleValue(
    props.bike.description!,
    useTolgee().value.getLanguage()!
  );
});
const lease_price = computed(() => {
  return getCurrentLocaleValue(
    props.bike.lease_price!,
    useTolgee().value.getLanguage()!
  );
});
</script>
