import FileUpload from './FileUpload';
import global from './global';
import toast from './toast';
import OverlayPanel from './OverlayPanel';
import Skeleton from './Skeleton';
import Panel from './Panel';
import InputOtp from './InputOtp';
import Image from './Image';
import Dialog from './Dialog';
import DatePicker from './DatePicker';
import Tag from './Tag';

export default {
  global,
  toast,
  OverlayPanel,
  FileUpload,
  Skeleton,
  Panel,
  InputOtp,
  Image,
  Dialog,
  DatePicker,
  Tag,
};
