<template>
    <DataTable :value="bookings" v-if="bookings" :pt="CalendarTable" sortField="start_date" :sortOrder="1"
        tableClass="border-0 no-border !py-0" bodyClass="!border-0 !border-b-0">

        <!-- Empty State -->

        <template #empty>
            <div class="w-full h-full flex flex-col justify-center items-center px-2">
                <p class="text-md text-neutral-300 md:w-[75%] text-center font-light">
                    {{ $t('testfleet.hr.calendar.bookings.empty.description') }}
                </p>
            </div>
        </template>


        <Column field="user.name" header="Name" class="!ps-1" style="min-width: 200px;">
            <template #body="slotProps">
                <span class="font-medium capitalize text-neutral-900">{{ slotProps.data.user.name }}
                    {{ slotProps.data.user.last_name }}
                </span>
            </template>
        </Column>
        <Column field="start_date" header="Start Datum" dataType="date" style="min-width: 200px;">
            <template #body="slotProps">
                <span class="font-light text-black inline-flex">
                    {{ formatDate(slotProps.data.start_date, $tolgee.getLanguage()!) }}
                </span>
            </template>
        </Column>
        <Column field="divider" header="divider" class="!px-0">
            <template #body>
                <ChevronRight :size="20" />
            </template>
        </Column>
        <Column field="end_date" header="Eind Datum" dataType="date" style="min-width: 200px;">
            <template #body="slotProps">
                <span class="font-light text-black inline-flex items-center">
                    {{ formatDate(slotProps.data.end_date,
                        $tolgee.getLanguage()!) }}
                </span>
            </template>
        </Column>

        <Column field="biker_tag" header="Biker Status">
            <template #body="slotProps">
                <Tag
                    style="min-width: 120px;"
                    v-if="showBikerTag(slotProps.data)" :severity="getBikerTagSeverity(slotProps.data)" class="w-full">
                    <div class="flex items-center gap-2 px-1">
                        <Bicycle :size="16" />
                        <span>
                            {{ $t('testfleet.hr.calendar.tooltip.status.' + getBikerTag(slotProps.data)) }}
                        </span>
                    </div>
                </Tag>
                <Tag v-else class="w-full" style="min-width: 120px;"></Tag>
            </template>
        </Column>

        <Column field="tag" header="Status">
            <template #body="slotProps">
                <Tag 
                    style="min-width: 120px;"
                    v-if="showTag(slotProps.data)" :severity="getTagSeverity(slotProps.data)" class="w-full">
                    <span>
                            HR: {{ $t('testfleet.hr.calendar.tooltip.status.' + getTagValue(slotProps.data)) }}
                        </span>
                </Tag>
                <Tag v-else class="w-full" style="min-width: 120px;"></Tag>
            </template>
        </Column>

        <Column field="picked_up_helmet" header="Helm" class="">
            <template #body="slotProps">
                <span v-if="slotProps.data.picked_up_helmet" class="text-neutral-300 w-4 h-4"
                    v-tooltip.top="$t('testfleet.hr.calendar.tooltip.helmet')">
                     <HelmetIcon class="w-6 h-6" />
                </span>
            </template>
        </Column>
        <Column class="w-full" />
        <Column :exportable="false" class="!ps-0">
            <template #body="innerProps">
                <PickupConfirm :booking="innerProps.data" />
            </template>

        </Column>
        <Column :exportable="false" class="!ps-0">
            <template #body="innerProps">
                <DropoffConfirm :booking="innerProps.data" />
            </template>

        </Column>
        <Column :exportable="false" class="!ps-0 !pe-2">
            <template #body="innerProps">
                <CancelReservation :booking="innerProps.data" :bicycle="bike" />
            </template>

        </Column>
        <Column :exportable="false" class="!ps-0">
            <template #body="innerProps">
                <div class="flex gap-3">
                    <!-- Create Remark Button -->
                    <RemarkModal :bike="bike" :bookingId="innerProps.data.id" :header="false" />
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script setup lang="ts">
import CalendarTable from '@/presets/o2o/CalendarTable'
import { Booking, BookingStatus } from '@/services/booking/types';
import { Bike } from '@/services/bikes/types';
import { formatDate } from '@/utils/date';
import DataTable from 'primevue/datatable';
// @ts-ignore
import CheckCircleOutline from "vue-material-design-icons/CheckCircleOutline.vue";
// @ts-ignore
import CheckCircle from "vue-material-design-icons/CheckCircle.vue";
// @ts-ignore
import CloseCircleOutline from "vue-material-design-icons/CloseCircleOutline.vue";
// @ts-ignore
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";
// @ts-ignore
import Check from "vue-material-design-icons/Check.vue";
// @ts-ignore
import MessageTextOutline from "vue-material-design-icons/MessageTextOutline.vue";
// @ts-ignore
import Close from "vue-material-design-icons/Close.vue";
// @ts-ignore
import RacingHelmet from "vue-material-design-icons/RacingHelmet.vue";
// @ts-ignore
import Bicycle from "vue-material-design-icons/Bicycle.vue";
import RemarkModal from '@/components/RemarkModal/RemarkModal.vue';
import Column from 'primevue/column';
import Tag from 'primevue/tag';
import CancelReservation from '@/components/Admin/CancelReservation.vue';
import PickupConfirm from '@/components/Admin/PickupConfirm.vue';
import DropoffConfirm from '@/components/Admin/DropoffConfirm.vue';
import HelmetIcon from '@/assets/icons/helmet.svg';

const { bookings, bike } = defineProps<{
    bookings: Booking[];
    bike: Bike;
}>();

const getTagValue = (booking: Booking): string | null => {
    if (booking.status === BookingStatus.Cancelled) return BookingStatus.Cancelled.toString();
    else if (booking.admin_dropoff_confirmed) return BookingStatus.DroppedOff.toString();
    else if (booking.admin_pickup_confirmed) return BookingStatus.PickedUp.toString();
    else return null;
}

const getTagSeverity = (booking: Booking): string => {
    if (booking.status === BookingStatus.Cancelled) return 'danger';
    else if (booking.admin_dropoff_confirmed) return 'success';
    else if (booking.admin_pickup_confirmed) return 'info';
    else return 'info';
}

const showTag = (booking: Booking): boolean => {
    return booking.status == BookingStatus.Cancelled || booking.admin_dropoff_confirmed! || booking.admin_pickup_confirmed!;
}


const getBikerTag = (booking: Booking): string | null => {
    if (booking.status === BookingStatus.PickedUp) return BookingStatus.PickedUp.toString();
    else if (booking.status === BookingStatus.DroppedOff) return BookingStatus.DroppedOff.toString();
    else return null
}

const getBikerTagSeverity = (booking: Booking): string => {
    if (booking.status === BookingStatus.PickedUp) return 'info';
    else if (booking.status === BookingStatus.DroppedOff) return 'success';
    else return 'info';
}

const showBikerTag = (booking: Booking): boolean => {
    // Only show the Biker tag if it is picked up or dropped off
    return booking.status == BookingStatus.PickedUp || booking.status == BookingStatus.DroppedOff;
}

</script>

<style scoped></style>