export default {

    root: {
      class: [
        "rounded-2xl",
        "border-2 border-primary-100",
        "bg-surface-0 dark:bg-surface-900",
      ],
    },
    header: ({ props: props2 } : any) => ({
      class: [
        // Flex
        "flex items-center justify-between",
        // Colors
        
        "bg-transparent",
        //Shape
        "rounded-tl-md rounded-tr-md",
        "border-0",
        // Conditional Spacing
        {
          "p-[1.125rem]": !props2.toggleable,
          "py-6 px-[1.125rem]": props2.toggleable,
        },
      ],
    }),
    title: {
      class: "leading-none font-semibold",
    },
    toggler: {
      class: [
        "inline-flex items-center justify-center",
        "relative",
        "w-7 h-7",
        "m-0 p-0",
        "border-0 rounded-full",
        "bg-transparent",
        "hover:text-surface-800",
        "hover:bg-surface-50",
        "focus:outline-none focus:outline-offset-0 focus-visible:ring-1 focus-visible:ring-primary-400",
        "transition-all duration-200 ease-in-out",
        "overflow-hidden no-underline",
        "cursor-pointer",
      ],
    },
    togglerIcon: {
      class: "inline-block w-4 h-4",
    },
    content: {
      class: [
        "p-[1.125rem] pt-0",
        "border-0 border-t-0 last:rounded-br-md last:rounded-bl-md",
        'font-roboto [&_b]:font-medium [&_a]:text-primary',
        "[&_a]:font-medium"
      ],
    },
    footer: {
      class: [
        "p-[1.125rem] pt-0",
        "border-0 border-t-0 rounded-br-lg rounded-bl-lg",
        "text-surface-700 dark:text-surface-0/80",
      ],
    },
    transition: {
      enterFromClass: "max-h-0",
      enterActiveClass:
        "overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]",
      enterToClass: "max-h-[1000px]",
      leaveFromClass: "max-h-[1000px]",
      leaveActiveClass:
        "overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]",
      leaveToClass: "max-h-0",
    },

};
